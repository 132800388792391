import * as React from "react";
import * as Sentry from "@sentry/react";
import { Camera, CameraResultType, CameraDirection, ImageOptions } from "@capacitor/camera";

import { useDeviceInfo } from ".";

interface UseCamera {
  takePicture: (options?: Omit<ImageOptions, "resultType">) => Promise<void>;
}

export const useCamera = (onChange?: (CameraPhoto: string) => void, onError?: () => void): UseCamera => {
  const deviceInfo = useDeviceInfo();

  const takePicture = React.useCallback(
    async (options) => {
      try {
        const image = await Camera.getPhoto({
          saveToGallery: true,
          direction: CameraDirection.Rear,
          quality: 80,
          // NOTE: This way Android doesn't take you to some editing app, but just returns the image. iOS allows simple edits and it is straightforward.
          // https://github.com/ionic-team/capacitor/issues/2089
          allowEditing: deviceInfo?.platform === "ios",
          resultType: CameraResultType.DataUrl,
          ...(options || {
            width: 1500,
            height: 1500,
          }),
        });

        if (image.dataUrl && onChange) {
          onChange(image.dataUrl);
        }
      } catch (err) {
        const error = JSON.stringify(err);
        // Check if the error message is one we actually care about.
        // In this case, user simply cancelled the camera interface, so safe to ignore it.
        // Evidence from xcode logs: {"message":"User cancelled photos app","errorMessage":""}
        if (error?.includes("cancelled")) return;
        // Note from migrating to capacitor 6:  Android should be in line from now on.
        // On Android, if the user cancels picking images from the gallery, the error returned is now "User cancelled photos app" as on the other platforms.
        // Cancelling makes the error an empty object
        //
        // See above, uncommenting this empty object catch
        if (error === "{}") {
          return;
        }
        // In all other cases, it could be something more severe and we should let ourselves know.
        Sentry.captureMessage(error);
        // Invoke the onError callback when provided.
        if (onError) onError();
      }
    },
    [Camera, onChange, deviceInfo?.platform]
  );

  return {
    takePicture,
  };
};
