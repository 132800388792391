import { SortByField } from "../store/search/types";
import { logger } from "../utils";

export interface JobFunnelEvent {
  event: "Job funnel";
  timeStamp: string;
  action: "impression" | "claim";
  id: string;
  listPosition: number;
  listLength: number | null;
  score: number | null;
  isDefaultScore: boolean | null;
  totalJobsCount: number | null;
  activeFilter: string;
  sortByField: SortByField;
  courierId: string | null;
}

export interface UserData {
  user_id: string;
}

export type DataLayerData = JobFunnelEvent | UserData;

export interface UseTracking {
  /**
   * Trigger events in GA
   */
  sendEvent(data: DataLayerData): void;
}

export function useTracking(): UseTracking {
  const sendEvent = (data: DataLayerData): void => {
    logger.dev(`GA Event`, data);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  };

  return {
    sendEvent,
  };
}
