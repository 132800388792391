import { Button, HorizontalLine, Label, Message, Spacer, useModalState } from "@brenger/react";
import cn from "classnames";
import * as React from "react";

import { LanguageSelect } from "../../../components";

import {
  useAuth,
  useDeviceInfo,
  useGeolocationMethods,
  usePushNotifications,
  useSelector,
  useTrackingInit,
  useTranslation,
} from "../../../hooks";

import { Link } from "react-router-dom";
import { Content, PageHead, Section, SheetModal } from "../../../layout";
import { Routes } from "../../../utils";
import { GeoPrimer, PushPrimer, TrackingPrimer } from "../../primers";

export const MoreAppSettings: React.FC = () => {
  const geoModal = useModalState();
  const notificationsModal = useModalState();
  const trackingModal = useModalState();
  const platform = useDeviceInfo()?.platform;
  const isNativePlatform = platform === "android" || platform === "ios";
  const auth = useAuth();
  const { t } = useTranslation();
  const push = usePushNotifications();
  const isPushBlocked = useSelector((state) => state.appSettings.push.oneSignalIsBlocked);
  const pushWebAndDenied = platform === "web" && push?.permission === "denied";
  const tracking = useTrackingInit();
  const geolocation = useGeolocationMethods();
  const bgGeoOn = geolocation.isBackgroundTrackingEnabled;
  return (
    <>
      <Content
        header={
          <PageHead
            sectionType="single-details"
            title={t((d) => d.more.location_language)}
            backUrl={Routes.more.home()}
          />
        }
      >
        <Section type="single-details" isTop={true}>
          <h4>{t((d) => d.settings.headings.main)}</h4>
          <HorizontalLine type={"gray"} spaceWidth={1} />
          <Label text={t((d) => d.settings.select_language)}>
            <LanguageSelect />
          </Label>
          <Spacer h={4} />
          {pushWebAndDenied && (
            <Message type="info" className="mt-2 mb-4">
              {t((d) => d.settings.notifications_blocked_web)}
            </Message>
          )}
          {!isPushBlocked && (
            <div>
              <Label text={t((d) => d.settings.notifications)}>
                <Button
                  disabled={pushWebAndDenied || push?.isLoading}
                  className={cn("w-full")}
                  buttonType={push?.isEnabled ? "primary-outline" : "primary"}
                  onClick={push?.isEnabled ? () => push?.toggleSubsription() : notificationsModal.open}
                >
                  {t(
                    (d) => d.settings[push?.isEnabled ? "notifications_button_disable" : "notifications_button_enable"]
                  )}
                </Button>
              </Label>
            </div>
          )}
          {platform === "ios" && tracking.trackStatus !== "restricted" && (
            <>
              <Spacer h={4} />
              <div>
                {/**
                 * FIXME: Needs testing IOS
                 * When the user switches to not authorized
                 * The only way to undo Google Tag Manager is reloading the window
                 */}
                <Label text={t((d) => d.settings.notifications)}>
                  <Button
                    disabled={tracking?.isLoading}
                    className={cn("w-full")}
                    buttonType={tracking?.trackStatus === "authorized" ? "primary-outline" : "primary"}
                    onClick={
                      tracking?.trackStatus === "authorized"
                        ? () => tracking.prompt({ onNotAuthorized: window.location.reload })
                        : trackingModal.open
                    }
                  >
                    {t((d) => d.settings.tracking.switch_text)}
                  </Button>
                </Label>
              </div>
            </>
          )}
          {isNativePlatform && (
            <>
              <Spacer h={4} />
              <Label text={t((d) => d.settings.geo_location)}>
                <Button
                  className={cn("w-full")}
                  buttonType={bgGeoOn ? "primary-outline" : "primary"}
                  onClick={() => {
                    if (bgGeoOn) {
                      geolocation.stop();
                    } else {
                      geoModal.open();
                    }
                  }}
                >
                  {bgGeoOn && t((d) => d.settings.disable_geolocation)}
                  {!bgGeoOn && t((d) => d.settings.enable_geolocation)}
                </Button>
              </Label>
            </>
          )}

          {auth.isAccountAdmin && (
            <div>
              <Spacer h={8} />
              <h4>{t((d) => d.settings.headings.forget_account)}</h4>
              <HorizontalLine type={"gray"} spaceWidth={1} />
              {t((d) => d.settings.forget_account_desc)}
              <Spacer h={2} />
              <Link to={Routes.more.forget()}>
                <Button type={"button"} buttonType={"warning-outline"} className="w-full">
                  {t((d) => d.settings.forget_account_button)}
                </Button>
              </Link>
            </div>
          )}
        </Section>
      </Content>
      <SheetModal isOpen={geoModal.isActive} close={geoModal.close}>
        <GeoPrimer geolocation={geolocation} closeHandler={geoModal.close} />
      </SheetModal>
      <SheetModal isOpen={notificationsModal.isActive} close={notificationsModal.close}>
        <PushPrimer push={push} closeHandler={notificationsModal.close} />
      </SheetModal>
      <SheetModal isOpen={trackingModal.isActive} close={trackingModal.close}>
        <TrackingPrimer tracking={tracking} closeHandler={trackingModal.close} />
      </SheetModal>
    </>
  );
};
