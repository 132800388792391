import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  useDeviceInfo,
  useGeolocationMethods,
  useHasSeenTour,
  usePushNotifications,
  useSelector,
  useTrackingInit,
} from "../../hooks";
import { Content } from "../../layout";
import { PATH_TO_CONTINUE, Routes } from "../../utils";
import { GeoPrimer, PushPrimer, TrackingPrimer } from "../primers";
import { Config } from "../../config";

export interface TourStepProps {
  goToPrev?(): void;
  goToNext(): void;
}

type TourStep = "push" | "geo" | "tracking";

// Set that holds step for the tour
// Yes outside of the component, state get's determined on initialize
// And not reconsidered after the user gave permission
const steps = new Set<TourStep>();

export const AppTour: React.FC = () => {
  const { set: setHasSeenTour } = useHasSeenTour();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const push = usePushNotifications();
  const isPushBlocked = useSelector((state) => state.appSettings.push.oneSignalIsBlocked);
  const platform = useDeviceInfo()?.platform;
  const tracking = useTrackingInit();
  const history = useHistory();
  const geolocation = useGeolocationMethods();

  // Loading
  const isPushLoading = !isPushBlocked && Config.ONE_SIGNAL_FCM_ID && (!push || push?.isLoading);
  const isLoading = !platform || tracking.isLoading || isPushLoading || geolocation.isLoading;

  const finish = (): void => {
    const pathToContinue = new URL(window.location.href).searchParams.get(PATH_TO_CONTINUE);
    // Save it with the persist setter function, which is second in array
    setHasSeenTour(true);
    // push to app
    history.push(pathToContinue || Routes.default());
  };

  const nextStep = (index: number): void => {
    // Are we finished?
    if (steps.size - 1 === index) {
      finish();
      return;
    }
    setActiveStep(index + 1);
  };

  React.useEffect(() => {
    if (isLoading || steps.size > 0) return;
    // We force a tour on everyone, but it could that every config is set right previously
    // We end up with zero steps, we wait for 1 second to take it easy on the router
    setTimeout(finish, 1000);
  }, [isLoading]);

  // ADDING PRIMERS SHOULD HAPPEN AFTER LOADING IS DONE
  // So important to return a loading state as long is the services are initializing/loading
  if (isLoading) {
    return <Content style="primary" isLoading={isLoading} />;
  }

  // Adding steps
  /**
   * Adding geo:
   * - Ios has specific not determined status === 0
   * - Android doesn't have not determined, so we go with "denied" === 2
   */
  const iosAskGeo = platform === "ios" && geolocation.provider?.status === 0;
  const androidAskGeo = platform === "android" && geolocation.provider?.status === 2;
  if (iosAskGeo || androidAskGeo) steps.add("geo");
  /**
   * Adding push:
   * Multiplatform, adding based on enabled. For already prompted users we switch on subscription
   */
  if (!isPushBlocked && Config.ONE_SIGNAL_FCM_ID && push && !push.isEnabled) steps.add("push");
  /**
   * Adding tracking:
   * Is for IOS only, see "get checkTrackingPermission" function in hook
   * Checks for not determined is enough.
   */
  if (tracking.trackStatus === "notDetermined") steps.add("tracking");
  // End adding steps

  return (
    <Content style="primary" isLoading={isLoading}>
      <div className="overflow-hidden absolute h-screen w-screen text-white">
        {Array.from(steps).map((step, i) => {
          return (
            <AppTourStepLayout key={i} active={activeStep === i} isNext={activeStep + 1 === i}>
              {step === "push" && <PushPrimer push={push} closeHandler={() => nextStep(i)} />}
              {step === "geo" && <GeoPrimer geolocation={geolocation} closeHandler={() => nextStep(i)} />}
              {step === "tracking" && <TrackingPrimer tracking={tracking} closeHandler={() => nextStep(i)} />}
            </AppTourStepLayout>
          );
        })}
        <div className="flex justify-center absolute inset-0 top-auto pb-4">
          {steps.size > 1 &&
            Array.from(steps).map((_, i) => {
              return (
                <div
                  key={i}
                  className={cn("h-2 w-2 bg-white transition-all rounded-full mx-1", {
                    "bg-white/25": activeStep !== i,
                  })}
                  onClick={() => setActiveStep(i)}
                />
              );
            })}
        </div>
      </div>
    </Content>
  );
};

const AppTourStepLayout: React.FC<{ active: boolean; isNext?: boolean }> = ({ active, isNext, children }) => {
  return (
    <div
      className={cn("absolute inset-4 bottom-8 overflow-auto transition-all opacity-0", {
        "!opacity-100": active,
        "translate-x-[-100vw]": !active && !isNext,
        "translate-x-[100vw]": isNext,
      })}
    >
      {children}
    </div>
  );
};
