import {
  CountryCode as CoreCountryCode,
  DateTimePeriodOpenTj,
  OpenTransportJob,
  parseCorePaginationControls,
} from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import orderBy from "lodash/orderBy";
import * as React from "react";
import { useDispatch } from "react-redux";

import { AxiosError } from "axios";
import { endOfDay } from "date-fns";
import { useAuth, useSelector } from ".";
import { searchFilterActions } from "../store/search/filter";
import { CountryCode, JobCondition, RegionNL, SortBy } from "../store/search/types";
import { CacheKey, StaleTTL, coreClient, dtpsFilterAvailableOpenTJ, isComboJob, routePlannerClient } from "../utils";

const countryCodeNeighborMap: { [key in CoreCountryCode]?: CoreCountryCode[] } = {
  BE: ["NL", "FR"],
  NL: ["BE", "DE"],
  DE: ["NL", "BE"],
  GB: ["NL", "BE"],
  FR: ["BE"],
};

/**
 * Small util that converts country codes to match filter options.
 * "nl" and "be" stay the same - all other country codes are converted to "other".
 */
const normalizeCountryCode = (rawCountryCode: string): CountryCode => {
  const cc = rawCountryCode.toLowerCase();
  if (cc === "nl" || cc === "be" || cc === "de" || cc === "gb" || cc === "es") return cc;
  return "other";
};

/**
 * Frontend is responsible for fetching ALL available pages of open transport jobs and handling the filtering.
 * Therefore, frontend needs to recursively follow the next page attribute and eagerly fetch the entire corpus
 * of results. To accomplish this, we create a helper that wraps the api-client method for open TJs and can
 * be passed to our useQuery hook.
 */
const openTransportJobsList = async (): Promise<OpenTransportJob[]> => {
  let data: OpenTransportJob[] = [];
  let nextPageNumber: number | undefined = 1;

  while (nextPageNumber) {
    const response = await coreClient.openTransportJobs.list({ page: nextPageNumber });
    const controls = parseCorePaginationControls(response);
    data = data.concat(response["hydra:member"]);
    // When there is no longer a next page, this will get set to undefined and stop the while loop.
    nextPageNumber = controls.nextPageNumber;
  }

  return data;
};

export type OpenTjExtended = OpenTransportJob & {
  /**
   * Will be added by grouping TJs by day method - see end of this file
   */
  currentDtp?: DateTimePeriodOpenTj;
  /**
   * DTPs - TJ without DTPs from the start appear in "other" at the bottom of the list grouping by date.
   * We filter out days in the past for openTJs, but we could also have OpenTJs without any DTPs from the start
   * That's why we should mark the TJ explicitly
   */
  hasDateTimePeriods?: boolean;
  /**
   * Is present when personalised filter is active
   */
  score?: { value: number; isDefaultScore: boolean };
};

interface UseSearchFilter {
  filteredOpenTransportJobs: OpenTjExtended[] | Record<string, OpenTjExtended[]>;
  filteredJobsCount: number;
  openTransportJobs: UseQueryResult<OpenTransportJob[], unknown>;
  isLoading: boolean;
  // Active filter is passed back, so we have a centralized view on the values which we can use for analysis or displaying above search results
  activeFilter: {
    sortBy: SortBy;
    hiddenJobIds: string[];
    pickupDate: string | null;
    countries: CountryCode[];
    regions: RegionNL[];
    conditions: JobCondition[];
    jobLevels: string[];
  };
}

export const useSearchFilter = (): UseSearchFilter => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { data: currentUserCountryCode } = useQuery([CacheKey.COUNTRY_CODE], routePlannerClient.geo.whatIsMyCountry, {
    staleTime: StaleTTL.XL,
  });

  const openTJs = useQuery([CacheKey.LIST_TRANSPORT_JOBS], openTransportJobsList, {
    staleTime: StaleTTL.XS,
  });

  const { countries, regions, sortBy, pickupDate, conditions, jobLevels, hiddenJobIds } = useSelector(
    (state) => state.searchFilter
  );
  // Personlized scores will fallback to the current user
  // And only fires when the filter is selected.
  const openTjScores = useQuery(
    [CacheKey.LIST_TRANSPORT_JOBS_SCORES, auth.userId],
    async () => {
      try {
        const scores = await routePlannerClient.geo.retrieveOpenTJScoresByUserId({ userId: auth.userId as string });
        return scores;
      } catch (e) {
        return null;
      }
    },
    {
      enabled: !!auth.userId,
      refetchOnWindowFocus: false,
      retry: (_, error: AxiosError) => {
        // FIXME: When 404-ing this hook triggers rerenders on retrying
        // Trying to keep amount of retries down, but some slip trough
        if (error?.code === "ERR_BAD_REQUEST") {
          return false;
        }
        return true;
      },
    }
  );

  // Using a memo here:
  // We either only care about the OpenTJs
  // Or we merge in the scores per Job
  // Filter out passed dates
  const openTransportJobs: OpenTjExtended[] | undefined = React.useMemo(() => {
    let jobs = (openTJs.data || [])
      .map((openTj) => {
        return {
          ...openTj,
          hasDateTimePeriods: !!(openTj.pickup_available_datetime_periods || []).length,
          /**
           * Filter out passed dates
           * the DTP end should be after "now"
           */
          pickup_available_datetime_periods: dtpsFilterAvailableOpenTJ({
            dtps: openTj.pickup_available_datetime_periods,
            isFlexibleDates: openTj.is_flexible_dates,
            isBundled: openTj.bundled,
          }) as DateTimePeriodOpenTj[],
          delivery_available_datetime_periods: dtpsFilterAvailableOpenTJ({
            dtps: openTj.delivery_available_datetime_periods,
            isFlexibleDates: openTj.is_flexible_dates,
            isBundled: openTj.bundled,
          }) as DateTimePeriodOpenTj[],
        };
      })
      .filter((job) => {
        if (job.hasDateTimePeriods && job.pickup_available_datetime_periods.length === 0) {
          // Meaning, we don't have any options left
          return false;
        }
        return true;
      });

    if (!openTJs.data?.length || sortBy.field !== "personalized" || !openTjScores.data?.scores?.length) {
      return jobs;
    }
    const scroreNotFound: string[] = [];
    // We do have jobs and scores, so merge them
    jobs = jobs.map((openTj) => {
      // find the score for the job
      const currentTjId = getIdFromIri(openTj["@id"]);
      const tjScore = openTjScores.data?.scores.find((openTjScore) => openTjScore.tj_id === currentTjId);
      if (!tjScore && currentTjId) {
        scroreNotFound.push(currentTjId);
      }
      return {
        ...openTj,
        score: {
          value: tjScore?.score || 0.75,
          isDefaultScore: !tjScore?.score,
        },
      };
    });
    /**
     * For debugging purposes we output the jobs that we couldn't find in the scores
     */
    try {
      if (localStorage.getItem("SHOW_TJ_SCORE_NOT_FOUND")) {
        // eslint-disable-next-line no-console
        console.log("TJs not found: ", scroreNotFound);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return jobs;
  }, [openTJs.data, openTjScores.data, sortBy.field]);

  const openTransportJobsTotalCount = openTransportJobs ? openTransportJobs.length : 0;

  // Check the hidden job list and remove anything that is no longer present in the open TJ response!
  React.useEffect(() => {
    if (openTransportJobs && hiddenJobIds.length) {
      // Convert open TJ list into a dict for quick look up as we iterate over all hidden TJ ids below.
      const map = openTransportJobs.reduce(
        (prev, curr) => {
          const id = getIdFromIri(curr);
          if (id) return { ...prev, [id]: true };
          return prev;
        },
        {} as { [key: string]: boolean }
      );

      // For each hidden TJ in state, check if the open TJ response from Core still has it.
      // It Core does not, then we can unset it from storage.
      hiddenJobIds.forEach((id) => {
        const entry = map[id];
        if (!entry) dispatch(searchFilterActions.setHiddenJobById(id));
      });
    }
    // Only re-run this effect when the total count of open TJs changes.
  }, [openTransportJobsTotalCount]);

  /**
   * Filtering all this data is an expensive operation. Therefore, only re-calculate...
   * ...when the dependency list changes.
   */
  const filteredOpenTransportJobs = React.useMemo(() => {
    let filteredResult = openTransportJobs || [];

    /**
     * FIRST - REMOVE HIDDEN TJS
     */
    if (hiddenJobIds.length) {
      filteredResult = filteredResult.filter((openTJ) => {
        const tjId = getIdFromIri(openTJ);
        return tjId && !hiddenJobIds.includes(tjId);
      });
    }

    /**
     * APPLY SPECIFIC PICKUP DATE
     */
    if (pickupDate) {
      const parsedPickupDate = parseISO(pickupDate);
      filteredResult = filteredResult
        .filter((openTJ) => {
          // Check those pickups that have a datetime periods
          if (openTJ.pickup_available_datetime_periods.length) {
            return openTJ.pickup_available_datetime_periods.some((dtp) =>
              isSameDay(parsedPickupDate, parseISO(dtp.period_start))
            );
          }
          // ASSUMPTION: All jobs without a datetime period can also be returned for this filter.
          return true;
        })
        // Then, Sort the filtered list by giving priority to jobs that do have a datetime period.
        .sort((openTJ) => {
          if (!openTJ.pickup_available_datetime_periods.length) {
            return 1;
          }
          return -1;
        });
    }

    /**
     * APPLY COUNTRY FILTER
     * Check FIRST and LAST stop address.
     */
    if (countries.length) {
      filteredResult = filteredResult.filter((openTJ) => {
        return (
          countries.includes(normalizeCountryCode(openTJ.first_stop_address.country_code)) ||
          countries.includes(normalizeCountryCode(openTJ.last_stop_address.country_code))
        );
      });
    }

    /**
     * APPLY REGIONS FILTER (NL ONLY FOR NOW)
     * Check FIRST and LAST stop address.
     */
    if (regions.length) {
      filteredResult = filteredResult.filter((openTJ) => {
        // Hack alert: convert to string and then to region so we can utilize toLowerCase method.
        const firstStopAdminArea = openTJ.first_stop_address.administrative_area as string;
        const lastStopAdminArea = openTJ.last_stop_address.administrative_area as string;
        return (
          regions.includes(firstStopAdminArea?.toLowerCase() as RegionNL) ||
          regions.includes(lastStopAdminArea?.toLowerCase() as RegionNL)
        );
      });
    }

    /**
     * APPLY JOB CONDITIONS FILTER
     */
    if (conditions.length) {
      filteredResult = filteredResult.filter((openTJ) => {
        return conditions.every((condition) => {
          switch (condition) {
            case "bundled":
              return openTJ.bundled;
            case "combo_job":
              return isComboJob(openTJ);
            case "prepaid":
              return openTJ.directly_claimable;
            case "equipment_tailgate":
              return openTJ.all_stop_details.carrying_help.includes("equipment_tailgate");
            case "extra_driver":
              return openTJ.all_stop_details.carrying_help.includes("extra_driver");
            case "assembly":
              return openTJ.all_services.includes("assembly");
          }
        });
      });
    }

    /**
     * APPLY JOB LEVEL FILTER
     */
    if (jobLevels.length) {
      filteredResult = filteredResult.filter((openTJ) => {
        // Only utilize this filter if a job level has actually been specified.
        if (jobLevels.length > 0) {
          return jobLevels.includes(openTJ.minimum_progress_level_required);
        }

        // If no job level has been specified, then return all jobs, no matter the min required level.
        return true;
      });
    }

    /**
     * AFTER ALL OTHER FILTERS HAVE BEEN APPLIED,
     * ELEVATE JOBS WITH PICKUP & DELIVERY ADDRESSES IN THE USER'S CURRENT COUNTRY.
     * https://gitlab.com/brenger/frontend/dd-v2/-/issues/48
     *
     * NOTE: Added exception for personalized, because this country sort could go against scored jobs
     */
    if (currentUserCountryCode && sortBy.field !== "personalized") {
      filteredResult = orderBy(
        filteredResult,
        (tj) => {
          const firstStopCountryCode = tj.first_stop_address.country_code;
          const lastStopCountryCode = tj.last_stop_address.country_code;

          // For this example assume that currentUserCountryCode === NL

          // Scenario: NL -> NL
          if (firstStopCountryCode === currentUserCountryCode && lastStopCountryCode === currentUserCountryCode) {
            return 1;
          }

          // Scenario: NL -> BE
          if (firstStopCountryCode === currentUserCountryCode) {
            return 2;
          }

          // Scenario: BE -> NL
          if (lastStopCountryCode === currentUserCountryCode) {
            return 3;
          }

          // Check if current user country code has neighbors that we care about.
          const neighbors = countryCodeNeighborMap[currentUserCountryCode];

          // Scenario: BE -> BE || BE -> DE
          if (neighbors) {
            if (neighbors.includes(firstStopCountryCode)) {
              return 4;
            }

            if (neighbors.includes(lastStopCountryCode)) {
              return 5;
            }
          }

          // Scenario: FR -> FR
          return 100;
        },
        ["asc"]
      );
    }

    // Returning both grouped and ungrouped
    // Which is easier to work with in case of result counts and job scores
    // Only execute sorting that we need to save up performance
    if (sortBy.field === "personalized") {
      return {
        jobs: groupByDate(filteredResult, sortBy, pickupDate),
        number: filteredResult.length,
      };
    }
    return {
      jobs: sortTjs(filteredResult, sortBy),
      number: filteredResult.length,
    };
  }, [
    openTransportJobs,
    countries,
    regions,
    conditions,
    jobLevels,
    sortBy.field,
    sortBy.order,
    pickupDate,
    currentUserCountryCode,
  ]);

  return {
    filteredOpenTransportJobs: filteredOpenTransportJobs.jobs,
    filteredJobsCount: filteredOpenTransportJobs.number,
    openTransportJobs: openTJs,
    isLoading: sortBy.field === "personalized" ? openTjScores.isLoading || openTJs.isLoading : openTJs.isLoading,
    // Active filter is passed back, so we have a centralized view on the values which we can use for analysis or displaying above search results
    activeFilter: {
      sortBy,
      hiddenJobIds,
      pickupDate,
      countries,
      regions,
      conditions,
      jobLevels,
    },
  };
};

const groupByDate = (
  jobs: OpenTjExtended[],
  sortBy: SortBy,
  pickupDate: string | null
): Record<string, OpenTjExtended[]> => {
  // object holding sorted jobs by date
  const grouped: Record<string, OpenTjExtended[]> = {};
  const pickupDateKey = pickupDate ? getGroupByDateKey(pickupDate, true) : null;
  // Little util to update Day groups
  const addToGroup = (key: string, job: OpenTjExtended): void => {
    // Get existing TJs
    const dtpJobs = grouped[key] || [];
    // Push to that list
    dtpJobs.push(job);
    // Overwrite the key with the new TJ list
    grouped[key] = dtpJobs;
  };

  // loop trough jobs
  jobs.forEach((job) => {
    // It could been explicity marked as no DTP available
    if (!job.hasDateTimePeriods) {
      addToGroup(NO_DTP_GROUP_KEY, job);
      return;
    }
    // keep track of already handled DTP keys, the job should only appear once per day
    const handledDtpKey: string[] = [];
    // for every job loop trough available DTPs
    job.pickup_available_datetime_periods.forEach((dtp) => {
      // Parse the date, set to start day to have a consistent key
      const dtpKey = getGroupByDateKey(dtp.period_start, !!job.hasDateTimePeriods);
      // 1. Check if we already added the job to this day
      // 2. Only show days were the courier is filtering for when filter is active
      if (handledDtpKey.includes(dtpKey) || (pickupDateKey && dtpKey !== pickupDateKey)) {
        return;
      }
      // Mark DTP as handled
      handledDtpKey.push(dtpKey);
      // add job
      addToGroup(dtpKey, { ...job, currentDtp: dtp });
    });
  });
  // Filter out the NO_DTP_KEY and CORRUPTED for now
  const sortedGroupKeys = Object.keys(grouped)
    .sort()
    .filter((groupKey) => ![NO_DTP_GROUP_KEY, CORRUPTED_DTP_GROUP_KEY].includes(groupKey));
  // Loop trough grouped day keys, and aply sorting
  const groupedSorted: Record<string, OpenTjExtended[]> = {};
  sortedGroupKeys.forEach((dtpKey) => {
    groupedSorted[dtpKey] = sortTjs(grouped[dtpKey], sortBy);
  });

  // Check if we had a NO_DTP group, add as last key and apply sorting
  if (grouped[NO_DTP_GROUP_KEY]) {
    groupedSorted[NO_DTP_GROUP_KEY] = sortTjs(grouped[NO_DTP_GROUP_KEY], sortBy);
  }
  // return result
  return groupedSorted;
};

export const sortTjs = (group: OpenTjExtended[], sortBy: SortBy): OpenTjExtended[] => {
  return orderBy(
    group,
    (tj) => {
      switch (sortBy.field) {
        case "created_at":
          return tj.created_at;
        case "price":
          return tj.total_payout_amount;
        // We default to Job score for two reasons:
        // - Deleted pickup date filter, so automagically personalized filtering will kick in
        // - Personalised is the future!
        default:
          return tj.score?.value;
      }
    },
    [sortBy.order]
  );
};

export const NO_DTP_GROUP_KEY = "NO_DTP";
export const CORRUPTED_DTP_GROUP_KEY = "CORRUPTED_DTP";
export const getGroupByDateKey = (start: string, hasDateTimePeriods: boolean): string => {
  if (!hasDateTimePeriods) {
    return NO_DTP_GROUP_KEY;
  }
  try {
    return endOfDay(parseISO(start)).toISOString();
  } catch {
    // For when we somehow deal with a none valid dateTimeString
    // Explicitly not show them in NO_DTP_GROUP because something is off with the data, so it will be only a crashy experience

    return CORRUPTED_DTP_GROUP_KEY;
  }
};
